import { Dimension, Padding } from "@superblocksteam/shared";
import React from "react";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { GeneratedTheme, TextStyleWithVariant } from "legacy/themes";
import { DROPDOWN_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import DropdownComponent, {
  INPUT_ADDITIONAL_MIN_HEIGHT,
  LABEL_EXTRA_HEIGHT_MARGIN,
  type DropDownComponentProps,
} from "./DropdownComponent";
import {
  DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
} from "./constants";

type WithLayoutManagedProps = DropDownComponentProps & {
  labelProps?: {
    textStyle: TextStyleWithVariant;
  };
  inputProps?: {
    textStyle: TextStyleWithVariant;
    padding?: Padding;
  };
};

export const DropdownComponentWithLayoutManaged = (
  props: WithLayoutManagedProps,
) => {
  const typographies = useAppSelector(selectGeneratedThemeTypographies);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.disabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  // inputProps.textStyleVariant already has the the default fallback in place.
  // This method still requires it.
  const inputLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: inputProps?.textStyleVariant,
    nestedProps: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    typographies,
  });

  const inputPadding = props.inputProps?.padding ?? DROPDOWN_PADDING;
  return (
    <DropdownComponent
      {...props}
      labelClassName={labelClass}
      labelStyleOverride={labelProps?.style}
      inputClassName={inputClass}
      inputStyleOverride={inputProps?.style}
      inputLineHeightPx={inputLineHeightPx}
      inputPadding={inputPadding}
    />
  );
};

export const estimateInitialDropdownWidgetHeightGU = (
  theme?: GeneratedTheme,
): number => {
  if (!theme?.typographies) {
    return 5;
  }

  const labelHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
  });

  const inputHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
  });

  const totalHeightPx =
    INPUT_ADDITIONAL_MIN_HEIGHT +
    labelHeight +
    inputHeight +
    LABEL_EXTRA_HEIGHT_MARGIN;

  return Dimension.toGridUnit(
    Dimension.px(totalHeightPx),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
