import { Dimension } from "@superblocksteam/shared/src/types/page/Dimension";
import type {
  Border,
  PerSideBorder,
  Typographies,
} from "@superblocksteam/shared/src/types/application";

export const THEME_STYLE_DEFAULTS = {
  primaryTextColor: "colors.contrastText",
  primaryBackgroundColor: "colors.primary500",
  secondaryTextColor: "colors.primary500",
  secondaryBorderColor: "colors.primary500",
  tertiaryTextColor: "colors.primary500",
};

export const DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT: keyof Typographies =
  "buttonLabel";

const DEFAULT_BUTTON_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "{{ theme.colors.primary500 }}",
};

const createButtonBorderObject = (
  override?: Partial<Border>,
): PerSideBorder => {
  return {
    left: { ...DEFAULT_BUTTON_BORDER, ...override },
    right: { ...DEFAULT_BUTTON_BORDER, ...override },
    top: { ...DEFAULT_BUTTON_BORDER, ...override },
    bottom: { ...DEFAULT_BUTTON_BORDER, ...override },
  };
};

export const DEFAULT_BUTTON_BORDER_OBJECT = createButtonBorderObject();

export const THEME_VALUE_BUTTON_BORDER_OBJECT = createButtonBorderObject({
  color: "transparent",
});
